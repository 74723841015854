import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { ServerService } from 'src/app/service/server.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/icon/logo.png' // Default Logo
  @Input() newsletter: boolean = true; // Default True
  
  public policies: any[] = [];
  public details: any;
  public today: number = Date.now();
  public subscribeForm: UntypedFormGroup;
  public showErrors: boolean = false;
  public model: any = {};
  public baseUrl: string = 'subscriber'

  constructor(
    public serverService: ServerService,
    public fb: UntypedFormBuilder,
    private router: Router
  ) { 
    this.subscribeForm = this.fb.group({
      em: ['', [Validators.required, Validators.email]]
    })

  }

  ngOnInit(): void {
    this.getAllPolicies();
    this.getDetails();
  }

  subscribe(){
    this.showErrors = false;
    this.model.em  = this.subscribeForm.get('em').value;
    if(!this.subscribeForm.invalid){
     
    return CommonService.subscriber(this).then((result)=>{
     
     if(result){
      Swal.fire({
        icon: 'success',
        title: 'Email Subscribed!',
        text: 'Thank you for your subscription!',
        showConfirmButton: true,
        confirmButtonText: 'Try again!',
        confirmButtonColor: '#FF0B00'
      })  
      }
     else{
      Swal.fire({
        icon: 'error',
        title: 'Email already exists!',
        text: 'The email address you have entered is already registered with another account! Please try again with another email address or login to your previous account!',
        showConfirmButton: true,
        confirmButtonText: 'Try again!',
        confirmButtonColor: '#FF0B00'
      })
    }

    })
  }
  else{
    this.showErrors = true;
  }
  }

  getDetails(){
    this.serverService.getAll('detail').subscribe(res=>{
      this.details = res.data[0];
    })
  }

  getAllPolicies(){
    this.serverService.getAll('policy').subscribe(res=>{
      this.policies = res.data;
    })
  }

  getUrl(name){
    return name.replace(/\s+/g, '-').toLowerCase();
  }

  

}
