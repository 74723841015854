import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd,NavigationStart, Router } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { ServerService } from 'src/app/service/server.service';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-sub-categories',
  templateUrl: './sub-categories.component.html',
  styleUrls: ['./sub-categories.component.scss']
})
export class SubCategoriesComponent implements OnInit {
  public categories: any[] = [];
  public subCategories: any[] =[];
  public categoryList: any[] = [];
  public collapse: boolean = true;
  public slugName: string;
  public dynamicContent: any = [];
  public baseUrl: string = 'category';
  isShowMore = true; 
  public noCategories: boolean = false;
  
  constructor(public productService: ProductService,
    private route: ActivatedRoute, 
    private router: Router,
    private serverService: ServerService,
    private viewScroller: ViewportScroller) { 
  }

  ngOnInit(): void {
    this.route.url.subscribe(res=>{
      if(res.length>0){
        let slug = res[res.length-1].path;
        this.slugName =  res[res.length-1].path;
        CommonService.fetchBySlug(this, slug).then(()=>{
          this.categories = this.dynamicContent.children;
          if(this.categories.length==0) this.noCategories = true;
         // console.log(this.categories);
        })

      }
      else{
        CommonService.fetchAll(this).then(()=>{
          this.categories = this.dynamicContent.filter(i=>i.level==1); 
          if(this.categories.length==0) this.noCategories = true;   
        })
      }
    })
  }

  scroll(category){
    this.router.navigateByUrl('/shop'+this.getRecursiveUrl(category.id))
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor('products');
      
  }

  ParseImage(imageObj){
    if(imageObj){
      let parsed = JSON.parse(imageObj)[0];
      return parsed.url+parsed.nm;
    } else{
      return;
    }
  }

  showMoreCategory() {
    this.isShowMore = !this.isShowMore
 }

  getRecursiveUrl(id){
    let url = '';
    let category = this.dynamicContent.filter(i=> i.id==id);
    url = '/'+category[0].slug;

    if(category[0].p==0){
      return url;
    } 
    else{
      return this.getParent(category[0].p, url);
    }
  }

  getParent(id, url){
    let parent = this.dynamicContent.filter(i=>i.id == id);
    url = '/'+parent[0].slug+url;
    if(parent[0].p==0){
      return url;
    } else{
      return this.getParent(parent[0].p, url);
    }
  }



  getActiveCategory(slug){
    if(this.router.url.split('/')[this.router.url.split('/').length-1] == slug){
      return true;
    }
  }

}
