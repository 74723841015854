<section class="testimonial small-section pt-70">
    <div class="container">
        <div class="title1 section-t-space">
  <h2 class="text-theme">Testimonials</h2>
</div>
        <div class="row">
            <div class="col-sm-9 mx-auto">
                <owl-carousel-o class="slide-2 testimonial-slider no-arrow" [options]="TestimonialSliderConfig">
                    <ng-container *ngFor="let ts of dynamicContent">
                      <ng-template carouselSlide>
                          <div>
                            <div class="media">
                                <div class="text-center">
                                    <img [src]="ParseImage(ts.zf)" alt="image">
                                    <h5>{{ts.nm}}</h5>
                                    <!-- <h6>{{ts.dsc}}</h6> -->
                                </div>
                                <div class="media-body">
                                    <p>{{ts.dsc}}</p>
                                </div>
                            </div>
                          </div>
                      </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>