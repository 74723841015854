import { Component, OnInit, Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from "../../services/product.service";
import { Product } from "../../classes/product";
import { Router } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { ServerService } from 'src/app/service/server.service';
import { CountService } from 'src/app/service/count.service';

@Component({
  selector: 'app-mb-fix',
  templateUrl: './mb-fix.component.html',
  styleUrls: ['./mb-fix.component.scss']
})
export class MbfixComponent implements OnInit {

  public products: any[] = [];
  public search: boolean = false;
  public userProfile: boolean = false;
  public mbCart: boolean = false;
  public userData: any = JSON.parse(localStorage.getItem('UserData'))

  public baseUrl: string = 'cart'
  public dynamicContent: any[] = [];
  public totalAmount: number = 0;
  public cartCount = 0;
  public whishListCount = 0;
  private cartCountSubscriber: any;
  private whishListCountSubscriber: any;
  
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    public productService: ProductService,
    private serverService: ServerService,
    private router: Router,
    private countService: CountService) {
    // this.productService.cartItems.subscribe(response => this.products = response);
    
    if(this.userData){
      CommonService.fetchAllCartItems(this).then(()=>{
        this.products = this.dynamicContent;
        this.getTotalAmount();
      });
    }
    
  }

  ngOnDestroy(): void {
    this.cartCountSubscriber.unsubscribe();
    this.whishListCountSubscriber.unsubscribe();
  }

  ngOnInit(): void {
    this.cartCountSubscriber = this.countService.$cartCount.subscribe(
      (value) => {
        this.cartCount = value;
      }
    );

    this.whishListCountSubscriber = this.countService.$wishListCount.subscribe(
      (value) => {
        this.whishListCount = value;
      }
    );

    if (this.userData) {
      this.countService.loadData(this.userData.id, true, true);
    }
  }

  ParseImage(imageObj){
    if(imageObj){
      let parsed = JSON.parse(imageObj)[0];
      return parsed.url+parsed.nm;
    } else{
      return;
    }
  }



  searchToggle(){
    this.search = !this.search;
  }

  userProfileToggle(){
    this.userProfile = !this.userProfile;
  }

  mbCartToggle(){
    this.mbCart = !this. mbCart;
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  getTotalAmount(){
    this.products.forEach((item)=>{
      this.totalAmount+=(parseInt(item.sp)*item.quantity);
    })
  }

  

  removeItem(id){
    console.log(id)
    CommonService.deleteById(this, id).then(()=>{
      CommonService.fetchAllCartItems(this).then(()=>{
        this.getTotalAmount();
        window.location.reload();
      })
    })
  }

  logout(){
    localStorage.removeItem('UserData');
    location.href = '';
  }

  Search(){
    let keyword = (document.getElementById('searchOverlay') as HTMLInputElement).value;
    if(keyword!="" && keyword!=null){
      this.router.navigateByUrl('/shop/search?keywords='+keyword);
      this.search = false;
    }
  }


  
}
