import {
	Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input, AfterViewInit,
	Injectable, PLATFORM_ID, Inject, Output, EventEmitter, ChangeDetectorRef
}                                           from '@angular/core';
import {isPlatformBrowser}                  from '@angular/common';
import {NgbModal, ModalDismissReasons}      from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {ServerService}                      from 'src/app/service/server.service';
import {CommonService}                      from 'src/app/service/common.service';
import * as moment                          from 'moment';

@Component({
	           selector   : 'app-review-modal',
	           templateUrl: './review-modal.component.html',
	           styleUrls  : ['./review-modal.component.scss']
           })

export class ReviewModalComponent implements OnInit, OnDestroy{

	@Input() productId: any;
	@ViewChild('reviewModal') ReviewModal: TemplateRef<any>;

	public closeResult: string;
	public modalOpen: boolean = false;
	public form: UntypedFormGroup;
	public baseUrl            = 'productReview';
	public model: any         = {
		id : null,
		pi : null,
		nm : null,
		em : null,
		ra : null,
		uv : null,
		dv : null,
		dsc: null,
		dt : null,
		zf : null,
		vu : 0,
		a  : 0
	};

	constructor(@Inject(PLATFORM_ID) private platformId: Object,
	            private modalService: NgbModal, private ref: ChangeDetectorRef,
	            private formBuilder: UntypedFormBuilder, private serverService: ServerService){

	}

	ngOnInit(){
		this.formBuild();
	}

	formBuild(){
		this.form = this.formBuilder.group(
			{
				name       : [this.model?.nm, [Validators.required]],
				description: [this.model?.dsc, [Validators.required]],
				email      : [this.model?.em, [Validators.required, Validators.email]]
			}
		);
	}

	async openModal(){
		this.model    = {
			id : null,
			pi : null,
			nm : null,
			em : null,
			ra : null,
			uv : null,
			dv : null,
			dsc: null,
			dt : null,
			zf : null,
			vu : 0,
			a  : 0
		};
		this.model.pi = this.productId;
		if(isPlatformBrowser(this.platformId)){ // For SSR
			await this.modalService.open(this.ReviewModal, {
				size       : 'md',
				backdrop   : 'static',
				keyboard   : false,
				centered   : true,
				windowClass: 'bd-example-modal-md theme-modal agem'
			}).result.then((result) => {
				`Result ${result}`;
			}, async(reason) => {
				return this.getDismissReason(reason);
			});
		}
	}

	private async getDismissReason(action: any){
		if(action){
			this.model.dt = moment().toDate();
			await CommonService.save(this);
			return 'Save';
		}
		return;
	}

	setRating(i){
		this.model.ra = i;
	}

	showSave(){
		return ((this.model.ra) && (this.form?.status === 'VALID'));
	}

	ngOnDestroy(){

	}

}





