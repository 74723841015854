import {
	Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input, AfterViewInit,
	Injectable, PLATFORM_ID, Inject, Output, EventEmitter, ChangeDetectorRef
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ServerService } from 'src/app/service/server.service';
import { CommonService } from 'src/app/service/common.service';
import * as moment from 'moment';

@Component({
	selector: 'app-address-modal',
	templateUrl: './address-modal.component.html',
	styleUrls: ['./address-modal.component.scss']
})

export class AddressModalComponent implements OnInit, OnDestroy {

	@Input() customerId: any;
	@Input() id: any;
	@ViewChild('addressModal') AddressModal: TemplateRef<any>;

	public closeResult: string;
	public modalOpen: boolean = false;
	public addressForm: UntypedFormGroup;
	public baseUrl = 'customerAddress';
	public states: any[];
	public selectedCities: any[] = [];
	public model: any = {
		// id: null,
		customer_id: null,
		type: null,
		name: null,
		phone: null,
		state: null,
		city: null,
		address: null,
		zip: null
	};


	constructor(@Inject(PLATFORM_ID) private platformId: Object,
		private modalService: NgbModal, private ref: ChangeDetectorRef,
		private formBuilder: UntypedFormBuilder, private serverService: ServerService) {

	}

	ngOnInit() {
		this.formBuild();
		this.getAllStatesCities();		
	}

	getAllStatesCities() {
		this.serverService.getAll('address').subscribe(res => {
			this.states = res.data;
			if(this.model?.state){
				this.filterCities(this.model?.state)
			}
		})
	}

	filterCities(state) {
		let selectedState: any = this.states.filter(i => i.state_id == state);
		this.selectedCities = selectedState[0].cities;
	}

	formBuild() {
		this.addressForm = this.formBuilder.group(
			{
				type: [this.model?.type, [Validators.required]],
				name: [this.model?.name, [Validators.required]],
				phone: [this.model?.phone, [Validators.required]],
				state: [this.model?.state, [Validators.required]],
				city: [this.model?.city, [Validators.required]],
				address: [this.model?.address, [Validators.required]],
				zip: [this.model?.zip, [Validators.required]]

			}
		);
	}

	async openModal(row) {
      
		//this.model.type = row.type;
		
		if(row){
			this.model = {
				id: row.id,
				customer_id: row.customer_id,
				type: row.type,
				name: row.name,
				phone: row.phone,
				state: row.state,
				city: row.city,
				address: row.address,
				zip: row.zip
			};
			this.filterCities(this.model.state)
		    console.log(this.selectedCities);
		}
		else{
		this.model = {
			// id: null,
			customer_id: null,
			type: null,
			name: null,
			phone: null,
			state: null,
			city: null,
			address: null,
			zip: null
		};
	    }

		if (isPlatformBrowser(this.platformId)) { // For SSR
			await this.modalService.open(this.AddressModal, {
				size: 'md',
				backdrop: 'static',
				keyboard: false,
				centered: true,
				windowClass: 'bd-example-modal-md theme-modal addressmodal'
			}).result.then((result) => {
				`Result ${result}`;
			}, async (reason) => {
				return this.getDismissReason(reason);
			});
		}
	}

	private async getDismissReason(action: any) {
		if (action) {
            
			this.model.type = this.addressForm.get('type').value;
			this.model.customer_id = this.customerId;
			this.model.name = this.addressForm.get('name').value;
			this.model.phone = this.addressForm.get('phone').value;
			this.model.state = this.addressForm.get('state').value;
			this.model.city = this.addressForm.get('city').value;
			this.model.address = this.addressForm.get('address').value;
			this.model.zip = this.addressForm.get('zip').value;
            

			if(this.model.id!=null){
				await CommonService.edit(this);
				return 'Save';
			}
			else{
				await CommonService.save(this);
			    return 'Save';
			}
			
		}
		return;
	}


	showSave() {
		return ((this.addressForm?.status === 'VALID'));
	}

	ngOnDestroy() {

	}
}





