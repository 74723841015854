import { Injectable } from "@angular/core";
import { ServerService } from "./server.service";
import { BehaviorSubject, Observable, Observer } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CountService {
  private _cartCount = 0;
  private _wishListCount = 0;
  private customerId = 0;
  private _isLoading = false;
  private cartCount = new BehaviorSubject(0);
  private wishListCount = new BehaviorSubject(0);
  private isLoading = new BehaviorSubject(false);

  public $cartCount: any = this.cartCount.asObservable();
  public $wishListCount: any = this.wishListCount.asObservable();
  public $isLoading: any = this.isLoading.asObservable();

  constructor(private serverService: ServerService) {}

  public loadData(id, loadCart, loadWishList) {
    this.customerId = id || this.customerId;

    if (this.customerId) {
      if (loadCart) {
        this.getCartCount();
      }

      if (loadWishList) {
        this.getWishListCount();
      }
    }
  }

  calculateAndUpdate(cart, increament) {
    if (cart) {
      this._cartCount = increament ? this._cartCount + 1 : this._cartCount - 1;
      this.triggerCartEvent(this._cartCount);
    } else {
      this._wishListCount = increament
        ? this._wishListCount + 1
        : this._wishListCount - 1;
      this.triggerWishListEvent(this._wishListCount);
    }
  }

  private triggerCartEvent(value) {
    this._cartCount = value;
    this.cartCount.next(value);
  }

  private triggerWishListEvent(value) {
    this._wishListCount = value;
    this.wishListCount.next(value);
  }

  public getCartCount() {
    this.serverService.getCartCount(this.customerId).subscribe((res: any) => {
      this.triggerCartEvent(res.data.count);
    });
  }

  public getWishListCount() {
    this.serverService
      .getWishListCount(this.customerId)
      .subscribe((res: any) => {
        this.triggerWishListEvent(res.data.count);
      });
  }

  public setLoading(value){
    this.isLoading.next(value);
  }
}
