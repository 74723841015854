<!-- Start Categories List -->
<div class="collection-collapse-block border-0" [class.open]="collapse">
    <h3 class="collapse-block-title" (click)="collapse = !collapse">Categories</h3>
    <div class="collection-collapse-block-content">
        <div class="filter-search">
            <input type="text"  name="cat_search_filter" class="cat_search_filter" placeholder="Search..." [(ngModel)]="filterTerm">
            <i class="fa fa-search" aria-hidden="true"></i>
        </div>
        <div class="collection-brand-filter" style="max-height: 420px;">
            <ul class="category-list">
                <li *ngFor="let category of categoryList | filter:filterTerm;">
                    <a href="javascript:void(0)" style="font-size: small;" [ngClass]="{'text-theme': getActiveCategory(category.slug)}" (click)="scroll(category)">
                        {{showDashes(category.level)}} {{ category.nm }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
<!-- End Categories List -->