<div class="navbar">
  <a href="javascript:void(0)" (click)="leftMenuToggle()">
    <div class="bar-style"><span>Shop by category</span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 80" x="0px" y="0px"><title></title><g data-name="15  Menu, Hamburger, Hamburger Menu"><path d="M32,5.5a27,27,0,1,0,27,27A27,27,0,0,0,32,5.5Zm0,52a25,25,0,1,1,25-25A25,25,0,0,1,32,57.5Zm-14-36H46v2H18Zm0,10H46v2H18Zm0,10H46v2H18Z"/></g><text x="0" y="79" fill="#000000" font-size="5px" font-weight="bold" font-family="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"></text><text x="0" y="84" fill="#000000" font-size="5px" font-weight="bold" font-family="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif">from the Noun Project</text></svg></div>
  </a>
  <div id="mySidenav" class="sidenav" [class.openSide]="navServices?.leftMenuToggle">
    <a href="javascript:void(0)" class="sidebar-overlay" (click)="leftMenuToggle()" 
                                                         (mouseover)="onHover(false)"></a>
    <nav id="unset">
      <div (click)="leftMenuToggle()">
        <div class="sidebar-back text-left"><i class="fa fa-angle-left pr-2" aria-hidden="true"></i> Back
        </div>
      </div>
      <ul id="sub-menu" class="sidebar-menu" (click)="leftMenuToggle()" 
      (mouseover)="onHover(false)">
        <li *ngFor="let menuItem of menuItems" (mouseover)="onHover(menuItem.children.length>0 ? true : false)">
          <a [routerLink]="'/shop/'+getUrl(menuItem.nm)">
             {{menuItem.nm}}
             <span class="sub-arrow" *ngIf="menuItem.children.length>0"></span>
          </a>

          <ul *ngIf="menuItem.children.length>0">
      
            <!-- Simple Menu Start-->
            <ng-container>
              <li *ngFor="let childrenItem of menuItem.children">
                <!-- Link -->
                <a class="has-submenu" [routerLink]="'/shop/'+getUrl(menuItem.nm)+'/'+getUrl(childrenItem.nm)">
                   {{childrenItem.nm}}
                </a>
      
                <!-- 3rd Level Menu -->
                <ul *ngIf="childrenItem.children.length>0">
                  <li *ngFor="let childrenSubItem of childrenItem.children">
                      <!-- Link -->
                      <a [routerLink]="'/shop/'+getUrl(menuItem.nm)+'/'+getUrl(childrenItem.nm)+'/'+getUrl(childrenSubItem.nm)">
                         {{childrenSubItem.nm}}
                      </a>

                      <!-- 4th Level Menu -->
                      <ul *ngIf="childrenSubItem.children.length>0">
                        <li *ngFor="let childrenSubSubItem of childrenSubItem.children">
                            <!-- Link -->
                            <a [routerLink]="'/shop/'+getUrl(menuItem.nm)+'/'+getUrl(childrenItem.nm)+'/'+getUrl(childrenSubItem.nm)+'/'+getUrl(childrenSubSubItem.nm)">
                               {{childrenSubSubItem.nm}}
                            </a>
                            <!-- 5th Level Menu -->
                            <ul *ngIf="childrenSubSubItem.children.length>0">
                              <li *ngFor="let childrenSubSubSubItem of childrenSubSubItem.children">
                                  <!-- Link -->
                                  <a [routerLink]="'/shop/'+getUrl(menuItem.nm)+'/'+getUrl(childrenItem.nm)+'/'+getUrl(childrenSubItem.nm)+'/'+getUrl(childrenSubSubItem.nm)+'/'+getUrl(childrenSubSubSubItem.nm)">
                                     {{childrenSubSubSubItem.nm}}
                                  </a>
                              </li>
                            </ul>
                        </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ng-container>
            <!-- Simple Menu End-->
      
          </ul>
        </li>
      </ul>

      
    </nav>
  </div>
</div>

