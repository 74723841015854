import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appOnlyNumbers]'
})
export class OnlyNumbersDirective {

  @Input('appOnlyNumbers') config: { allowFloat: boolean };


  private floatRegex: RegExp = new RegExp(/^-?\d*(\.\d*)?$/);
  private intRegex: RegExp = new RegExp(/^-?\d*$/);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];



  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow: Backspace, delete, tab, escape, enter, etc.
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

   // Determine the regex to use based on the configuration
   const regex = this.config?.allowFloat ? this.floatRegex : this.intRegex;

   // Allow only numbers and possibly decimal points
   const current: string = this.el.nativeElement.value;
   const next: string = current.concat(event.key);
   if (next && !String(next).match(regex)) {
     event.preventDefault();
   }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedInput = clipboardData?.getData('text/plain');
    
    // Determine the regex to use based on the configuration
    const regex = this.config?.allowFloat ? this.floatRegex : this.intRegex;

    if (pastedInput && !String(pastedInput).match(regex)) {
      event.preventDefault();
    }
  }

}
