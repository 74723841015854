import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AgeVerificationComponent } from '../modal/age-verification/age-verification.component';

@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.scss']
})
export class TopHeaderComponent implements OnInit {

  @ViewChild("ageVerification") AgeVerificationModal: AgeVerificationComponent;
  
  public location: any;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.location = JSON.parse(localStorage.getItem('location'));
  }

  updateLocation(event){
    this.location = JSON.parse(localStorage.getItem('location'));
  }

  search(){
    let keyword = (document.getElementById('searchBox') as HTMLInputElement).value;
    if(keyword!="" && keyword!=null){
      this.router.navigateByUrl('/shop/search?keywords='+keyword)
    }
  }

}
