<!--modal popup start-->
<ng-template class="theme-modal" #newsletter let-modal>
    <div class="modal-content">
        <div class="modal-body modal7">
            <div class="container-fluid p-0">
                <div class="row">
                    <div class="col-12">
                        <div class="modal-bg">
                            <button type="button" class="close"  aria-label="Close" (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="offer-content text-center">
                                <img src="assets/images/gifs/success.gif" class="img-fluid" alt="" style="width: 150px;">
                                <h2>Order Placed Successfully!</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!--modal popup end-->