import { Component, OnInit, Input, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgeVerificationComponent } from '../../components/modal/age-verification/age-verification.component';
import { QuickViewComponent } from '../../components/modal/quick-view/quick-view.component';

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {
  
  
  
  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() topbar: boolean = false; // Default True
  @Input() sticky: boolean = false; // Default false
  
  public stick: boolean = false;
  public title: string;
  

  constructor(
    private activatedRoute: ActivatedRoute,
  ) { 
    this.activatedRoute.data.subscribe(res=>{
      this.title = res.title;
    })
  }

  ngOnInit(): void {
    
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  	if (number >= 150 && window.innerWidth > 300) { 
  	  this.stick = true;
  	} else {
  	  this.stick = false;
  	}
  }

}
