<div class="container">
    <section class="section-b-space">
        <div class="row">
            <div class="col">
                <div class="slide-6 no-arrow">
                    <owl-carousel-o [options]="CategorySliderConfig">

                        <ng-template carouselSlide *ngFor="let cat of categories">
                            <div class="category-block">
                                <a [routerLink]="['/shop/'+getUrl(cat.nm)]">
                                    <div class="text-center">
                                        <img [src]="ParseImage(cat.zf)" alt="" style="border-radius: 50%; object-fit: cover; width: 150px; height: 150px; display: inline;">
                                    </div>
                                </a>
                                <div class="category-details">
                                    <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                                        <p>{{cat.nm}}</p>
                                    </a>
                                </div>
                            </div>
                        </ng-template>

                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </section>
</div>