<div class="top-header" style="background-color: #bc0017;">
    <div class="top-nav">
			<div class="container">
				
				<ul class="navbar-nav d-md-flex d-lg-flex d-sm-none d-none">
					
					<li class="nav-item">
						<p class="text-center">Use coupon Heksh2024 Get Flat 10% offer!</p>
					</li>
					
				</ul>
				<!--<ul class="navbar-nav navbar-icons">
					<li class="nav-item" *ngIf="location">
						<a (click)="AgeVerificationModal.openModal()" href="javascript:void(0)">
							<span class="fa fa-map-marker mr-1"></span>
							{{location.city}}
						</a>
					</li>
				</ul>-->
			</div>
		</div>
  </div>

  
<!--<app-age-verification (locationFlag)="updateLocation($event)" #ageVerification></app-age-verification>-->