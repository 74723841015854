
<ng-template class="theme-modal reviewmodal" #reviewModal let-modal>
    <div class="modal-content">
        <div class="modal-body modal13 reviewmodal">
            <div class="container-fluid p-0">
                <form [formGroup]="form" novalidate>
                <div class="row">
                    <div class="col-12">
                        <div class="modal-bg">
                            <button type="button" class="close"  aria-label="Close" (click)="modal.dismiss(0)">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="age-content">
                                <h2> Write a review</h2>
                               
                                <div class="col-md-12">
                                    <label>Name</label>
                                    <input type="text" class="form-control" placeholder="Enter Your name" formControlName="name" [(ngModel)]="model.nm">
                                </div>
                                <div class="col-md-12">
                                    <label>Email</label>
                                    <input type="text" class="form-control" placeholder="Email" required formControlName="email" [(ngModel)]="model.em">
                                </div>
                                <div class="col-md-12">
                                    <div class="media pb-20">
                                        <label>Rating</label>
                                        <div class="media-body ms-3 ml-2">
                                            <div class="rating">
                                                <i class="fa fa-star" *ngFor="let i of [1, 2, 3, 4, 5]" (click)="setRating(i)" [ngClass]="{'selected':(model.ra >= i)}"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label>Your Review </label>
                                    <textarea class="form-control" placeholder="Wrire your review" rows="6"
                                              required formControlName="description" [(ngModel)]="model.dsc"></textarea>
                                </div>
                                <div class="col-md-12">
                                    <button class="btn btn-solid" type="submit" (click)="modal.dismiss(1)" [disabled]="!showSave()">Submit Your Review</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>
