
<ng-template class="theme-modal addressmodal" #addressModal let-modal>
    <div class="modal-content">
        <div class="modal-body modal13 reviewmodal">
            <div class="container-fluid p-0">
                <form [formGroup]="addressForm" novalidate>
                <div class="row">
                    <div class="col-12">
                        <div class="modal-bg">
                            <button type="button" class="close"  aria-label="Close" (click)="modal.dismiss(0)">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="age-content">
                                <h2> Add Address</h2>
                               <div class="row">
                                <div class="col-md-6">
                                <label>Address Type</label>
                                <input type="text" class="form-control" placeholder="Enter Address Type" name="type" formControlName="type" [(ngModel)]="model.type">
                               </div>
                               <div class="col-md-6">
                                <label>Name</label>
                                <input type="text" class="form-control" placeholder="Enter Your name" name="name" formControlName="name" [(ngModel)]="model.name">
                                </div>
                                </div>
                                <div class="row">
                                <div class="col-md-6">
                                    <label>Phone</label>
                                    <input type="text" class="form-control" placeholder="Phone" required name="phone" formControlName="phone" [(ngModel)]="model.phone">
                                </div>
                                <div class="col-md-6">
                                    <label>State</label>
                                    <select class="form-control" name="state" formControlName="state" [(ngModel)]="model.state" (change)="filterCities($event.target.value)">
                                        <option value="0" selected disabled>--Select State--</option>
                                        <option *ngFor="let state of states" [value]="state.state_id">{{state.state_name}}</option>
                                    </select>
                                </div>
                                </div>
                                <div class="row">
                               
                                <div class="col-md-6">
                                    <label>City</label>
                                    <select name="city" class="form-control" formControlName="city" [(ngModel)]="model.city">
                                        <option value="0" selected disabled>--Select City--</option>
                                        <option *ngFor="let city of selectedCities" [value]="city.id">{{city.city}}</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label>Zipcode</label>
                                    <input type="text" class="form-control" placeholder="Zipcode" required name="zip" formControlName="zip" [(ngModel)]="model.zip">
                                </div>
                                </div>
                                <div class="row">
                                <div class="col-md-6">
                                    <label>Address</label>
                                    <textarea name="address" class="form-control"  placeholder="" autocomplete="off"  formControlName="address" [(ngModel)]="model.address"></textarea>
                                </div>
                               
                                </div>
                                <div class="col-md-12">
                                    <button class="btn btn-solid" type="submit" (click)="modal.dismiss(1)" [disabled]="!showSave()">Save Address</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>
