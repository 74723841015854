import { Component, Input, OnInit } from '@angular/core';
import { CategorySlider } from '../../data/slider';

@Component({
  selector: 'app-categories-carousel',
  templateUrl: './categories-carousel.component.html',
  styleUrls: ['./categories-carousel.component.scss']
})
export class CategoriesCarouselComponent implements OnInit {

  @Input() categories: any[];
  public CategorySliderConfig: any = CategorySlider;
  
  constructor() { }

  ngOnInit(): void {
  }

  ParseImage(imageObj){
    if(imageObj){
      let parsed = JSON.parse(imageObj)[0];
      return parsed.url+parsed.nm;
    } else{
      return;
    }
  }

  getUrl(text){
    return text.replace(/\s+/g, '-').toLowerCase();
  }
  

}
