<!--modal popup start-->
<ng-template class="theme-modal" #ageVerification let-modal>
	<div class="modal-content">
        <div class="modal-body modal13">
            <div class="container-fluid p-0">
                <div class="row">
                    <div class="col-12">
                        <div class="modal-bg">
                            <div class="age-content">
                                <h2> Whats your location?</h2>
                                <h4>To make sure that the product is available in your area, we need your location.</h4>
                                <form id="demoForm">
                                    <div>
                                       <select [formControl]="ageVerificationForm.controls['state']" name="" class="form-control" (change)="filterCities()">
                                           <option value="">--Select State--</option>
                                           <option *ngFor="let content of dynamicContent" [value]="content.state_id">{{content.state_name}}</option>
                                       </select>

                                       <select [formControl]="ageVerificationForm.controls['city']" name="" class="form-control">
                                            <option selected value="">--Select City--</option>
                                            <option *ngFor="let city of cities" [value]="city.id">{{city.city}}</option>
                                        </select>
                                    </div>
                                    <button type="button" (click)="ageForm()" class="btn btn-solid btn-theme">
                                    	submit
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!--modal popup end-->





