import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';
import { ServerService } from 'src/app/service/server.service';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit {

  public dynamicContent: any[] = [];
  public menuItems: any[] = [];
  public baseUrl: string = 'category'

  constructor(private router: Router, public navServices: NavService,  private serverService: ServerService) {
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }

  ngOnInit(): void {
    CommonService.fetchAll(this).then(()=>{
      this.menuItems = this.dynamicContent.filter((i)=> i.level==1);
			this.menuItems.forEach((cat)=>{
				this.getChild(cat)
			})
    })
  }

  getChild(cat){
		cat.children = this.dynamicContent.filter(i => i.p==cat.id);
		if(cat.children.length>0){
			cat.children.forEach((child)=>{
				this.getChild(child);
			})
		} else{
			return;
		}
	}

  getUrl(text){
    return text.replace(/\s+/g, '-').toLowerCase();
  }

  leftMenuToggle(): void {
    this.navServices.leftMenuToggle = !this.navServices.leftMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

  onHover(menuItem) {
    if(window.innerWidth > 1200 && menuItem){
       document.getElementById('unset').classList.add('sidebar-unset')
    } else {
      document.getElementById('unset').classList.remove('sidebar-unset')
    }
  }

}
