import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { ServerService } from 'src/app/service/server.service';
import { TestimonialSlider } from '../../data/slider';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {

  public dynamicContent: any[] = [];
  public baseUrl: string = 'testimonial'

  public TestimonialSliderConfig: any = TestimonialSlider;

  constructor(
    private serverService: ServerService
  ) { }

  ngOnInit(): void {
    CommonService.fetchAll(this).then(()=>{
    })
  }

  ParseImage(imageObj){
    if(imageObj){
      let parsed = JSON.parse(imageObj)[0];
      return parsed.url+parsed.nm;
    } else{
      return;
    }
  }

}
