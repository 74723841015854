<ng-template class="theme-modal" #quickView let-modal>
    <div class="modal-content quick-view-modal">
        <div class="modal-body">
            <button type="button" class="close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="row">
                <div class="col-lg-6  col-xs-12">
                    <div class="quick-view-img">
                        <img [src]="ImageSrc ? ImageSrc : ParseImage(product.zf)" [alt]="getUrl(product.nm)" class="img-fluid">
                    </div>
                </div>
                <div class="col-lg-6 rtl-text">
                    <div class="product-right">
                        <h2 class="p-0 m-0">{{ product.nm | titlecase }}</h2>
                        <small>{{product?.category_name}}</small>
                        <h3 *ngIf="product?.orderType==1">₹{{product?.sp}}</h3>

                        <div class="border-product">
                            <h6 class="product-title">product details</h6>
                            <p [innerHTML]="product.dsc.substring(0, 200)+'...'"></p>
                        </div>
                        <div class="product-description border-product" *ngIf="product?.orderType==1">

                            <h5 class="avalibility" *ngIf="counter <= product.stock"><span>In Stock</span></h5>
                            <h5 class="avalibility" *ngIf="counter > product.stock"><span>Out of Stock</span></h5>
                            <h6 class="product-title">quantity</h6>
                            <div class="qty-box">
                                <div class="input-group">
                                    <span class="input-group-prepend">
                                        <button type="button" class="btn quantity-left-minus" data-type="minus" (click)="decrement()">
                                            <i class="ti-angle-left"></i>
                                        </button>
                                    </span>
                                    <input type="text" name="quantity" class="form-control input-number"  [value]="counter" disabled>
                                    <span class="input-group-prepend">
                                        <button type="button" class="btn quantity-right-plus" data-type="plus" (click)="increment()">
                                            <i class="ti-angle-right"></i>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="product-buttons">
                            <a href="javascript:void(0)" *ngIf="product?.orderType==1" class="btn btn-solid btn-theme btn-block m-0" [class.disabled]="counter > product.stock" (click)="addToCart(product)">add to cart</a>
                            <a href="javascript:void(0)" *ngIf="product?.orderType==3" class="btn btn-solid btn-theme btn-block m-0" [class.disabled]="counter > product.stock" (click)="addToCart(product)">add to cart</a>
                            <a href="javascript:void(0)" *ngIf="product?.orderType==2" class="btn btn-solid btn-theme btn-block m-0" [routerLink]="['/buyer/price-inquiry/'+product?.id]">Order Via Enquiry</a>
                            <!-- <a [routerLink]="['/shop/product/left/sidebar/', product.nm.replace(' ', '-')]" class="btn btn-solid">view detail</a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>