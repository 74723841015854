import {
	Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input, AfterViewInit,
	Injectable, PLATFORM_ID, Inject, Output, EventEmitter, ChangeDetectorRef
}                                           from '@angular/core';
import {isPlatformBrowser}                  from '@angular/common';
import {NgbModal, ModalDismissReasons}      from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {ServerService}                      from 'src/app/service/server.service';
import {CommonService}                      from 'src/app/service/common.service';

@Component({
	           selector   : 'app-age-verification',
	           templateUrl: './age-verification.component.html',
	           styleUrls  : ['./age-verification.component.scss']
           })
export class AgeVerificationComponent implements OnInit, AfterViewInit, OnDestroy{

	@ViewChild('ageVerification') AgeVerificationModal: TemplateRef<any>;

	@Output() locationFlag: EventEmitter<any> = new EventEmitter<any>();

	public closeResult: string;
	public ageVerificationForm: UntypedFormGroup;

	public dynamicContent: any[];
	public cities: any[]   = [];
	public baseUrl: string = 'address';

	public location: any = JSON.parse(localStorage.getItem('location'));

	constructor(@Inject(PLATFORM_ID) private platformId: Object,
	            private modalService: NgbModal, private ref: ChangeDetectorRef,
	            private fb: UntypedFormBuilder, private serverService: ServerService){
		this.ageVerificationForm = this.fb.group({
			                                         state: [this.location ? this.location.state_id : '', Validators.required],
			                                         city : [this.location ? this.location.city_id : '', Validators.required],
		                                         });
	}

	ngOnInit(): void{
		CommonService.fetchAll(this).then(() => {
			if(this.ageVerificationForm.get('state').value != 0){
				this.filterCities();
			}
		});
	}

	filterCities(){
		let selectedState: any = this.dynamicContent.filter(i => i.state_id == this.ageVerificationForm.get('state').value);
		this.cities            = selectedState[0] && selectedState[0].cities || [];
	}

	ngAfterViewInit(): void{
		if(!localStorage.getItem('location')){
			this.openModal();
		}
	}

	openModal(){
		if(isPlatformBrowser(this.platformId)){ // For SSR
			this.modalService.open(this.AgeVerificationModal, {
				size       : 'md',
				backdrop   : 'static',
				keyboard   : false,
				centered   : true,
				windowClass: 'bd-example-modal-md theme-modal agem'
			}).result.then((result) => {
				`Result ${result}`;
			}, (reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			});
		}
	}

	ageForm(){
		let selectedCity = this.cities.filter(c => c.id == this.ageVerificationForm.get('city').value);
		let location     = {
			availability: this.ageVerificationForm.get('city').value || null,
			city        : selectedCity[0] && selectedCity[0].city || null,
			city_id     : this.ageVerificationForm.get('city').value || null,
			state_id    : this.ageVerificationForm.get('state').value || null
		};
		localStorage.setItem('location', JSON.stringify(location));
		this.locationFlag.emit(location);
		this.modalService.dismissAll();

	}

	private getDismissReason(reason: any): string{
		if(reason === ModalDismissReasons.ESC){
			return 'by pressing ESC';
		} else if(reason === ModalDismissReasons.BACKDROP_CLICK){
			return 'by clicking on a backdrop';
		} else{
			return `with: ${reason}`;
		}
	}

	ngOnDestroy(){

	}

}
