import { Component, OnInit, Input } from '@angular/core';
import { CountService } from '../service/count.service';

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss']
})
export class PreloaderComponent {

  isLoading = true;


  constructor(private countService : CountService) {
    // Simulate loading time
    setTimeout(() => {
      this.isLoading = false; // Hide preloader after loading
    }, 120000); // Adjust the duration as needed


    this.countService.$isLoading.subscribe(
      (value) => {
        this.isLoading = value;
      }
    );
  }

}
